import React from 'react'
import Banners from '../../Components/Banners/Banners'
import './Demo.css'
import Footer from '../../Components/Footer/Footer'
import NewProducBtn from '../../Components/NewProducBtn/NewProducBtn'
import Products from '../../Components/Products/Products'
import BannersHome from '../../Components/BannersHome/BannersHome'
import BannersModal from '../../Components/BannersModal/BannersModal'
import PublicacionesHome from '../../Components/PublicacionesHome/PublicacionesHome'
import TitleSection from '../../Components/TitleSection/TitleSection'
import logo from '../../images/logo.png'
export default function Demo() {
    return (
        <section className='demo'>
            <Banners />
            <BannersHome />
            <BannersModal />
            <Products />
            <TitleSection title='Motos de Segunda Blog' description="Todo lo que necesitas saber sobre nuestro Blog" logo={logo} link='/blog' />
            <PublicacionesHome />
            <NewProducBtn />
            <Footer />

        </section>
    )
}
