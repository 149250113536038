const contact = [


    {
        nombre: 'Motos de segunda',
        telefono: 54387583101,
        email: 'motosdesegundagmail.com',
        direccion: 'Colombia',
        instagram: 'https://www.instagram.com/',
        facebook: 'https://www.facebook.com/'
    }

]
export default contact