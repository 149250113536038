import React, { useEffect, useState, useRef } from 'react';
import baseURL from '../url';
import './Products.css';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProductosLoading from '../ProductosLoading/ProductosLoading';
import { Link as Anchor } from "react-router-dom";
import moneda from '../moneda';
import Modal from 'react-modal';
import logo from '../../images/logo.png';
SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function Products() {
    const [marcas, setMarcas] = useState([]);
    const [loading, setLoading] = useState(true);
    const marcasRefs = useRef([]);
    const marcasInputRef = useRef(null);
    const swiperRef = useRef(null);
    const [productos, setProductos] = useState([]);
    const [marcaSeleccionada, setMarcaSeleccionada] = useState('Todo');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [contactos, setContactos] = useState([]);
    const [contactoSeleccionado, setContactoSeleccionado] = useState(null);

    const handleClickMarca = (marca) => {
        setMarcaSeleccionada(marca);
    };
    const openModal = (contacto) => {
        setContactoSeleccionado(contacto);  // Nueva función para almacenar el contacto seleccionado
        setModalIsOpen(true);
    };


    const closeModal = () => {
        setModalIsOpen(false);
    };
    useEffect(() => {
        cargarProductos();
        cargarContacto();

    }, []);
    const cargarContacto = () => {
        fetch(`${baseURL}/contactoGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setContactos(data.contacto || []);
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };


    const cargarProductos = () => {
        fetch(`${baseURL}/productosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setProductos(data.productos.reverse().slice(0, 40));
                const marcasUnicas = [...new Set(data.productos.map(producto => producto.marca))];
                setMarcas(marcasUnicas);
                setLoading(false);
            })
            .catch(error => console.error('Error al cargar productos:', error));
    };

    const obtenerImagen = (item) => {
        if (item.imagen1) {
            return item.imagen1;
        } else if (item.imagen2) {
            return item.imagen2;
        } else if (item.imagen3) {
            return item.imagen3;
        } else if (item.imagen4) {
            return item.imagen4;
        }
        return null;
    };

    const marcasConProductos = marcas.filter(marca =>
        productos?.filter(producto => producto?.marca === marca).length > 0
    );



    const handleWhatsappMessage = (producto) => {
        console.log('Producto:', producto);

        const contacto = contactos.find(c => c.idUsuario === producto.idUsuario);

        if (!contacto) {
            console.error('No se encontró el contacto para el usuario');
            return;
        }

        const phoneNumber = contacto.telefono;
        const name = contacto.nombre;
        const title = encodeURIComponent(producto?.titulo?.replace(/\s+/g, '-'));
        const formattedPrice = Number(producto?.precio).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        const message = `Hola ${name}🌟, quisiera más información sobre\n\n✅ *${title}*\n      ${moneda} ${formattedPrice}`;

        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

        window.open(whatsappUrl, '_blank');
    };


    // Función para manejar el clic en WhatsApp
    const handleWhatsappClick = () => {
        const phoneNumber = contactoSeleccionado.telefono;
        const message = `Hola ${contactoSeleccionado.nombre}, me gustaría obtener más información.`;
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, '_blank');
    };

    // Función para manejar el clic en Teléfono (realizar una llamada)
    const handleCallClick = () => {
        const phoneNumber = contactoSeleccionado.telefono;
        window.location.href = `tel:${phoneNumber}`;
    };

    // Función para manejar el clic en Email (abrir el cliente de correo)
    const handleEmailClick = () => {
        const email = contactoSeleccionado.email;
        const subject = encodeURIComponent("Solicitud de información");
        const body = encodeURIComponent(`Hola ${contactoSeleccionado.nombre},\n\nMe gustaría obtener más información sobre el producto.`);
        window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
    };


    return (
        <div className='ProductsContain'>
            <ToastContainer />
            {productos?.length > 0 && (
                <div className={`categoriasInputs`} ref={marcasInputRef}>
                    <input
                        type="button"
                        value="Todo"
                        onClick={() => handleClickMarca('Todo')}
                        style={{
                            backgroundColor: marcaSeleccionada === 'Todo' ? '#000' : '',
                            color: marcaSeleccionada === 'Todo' ? '#fff' : '',
                            borderBottom: marcaSeleccionada === 'Todo' ? '2px solid #000' : 'none'
                        }}
                    />
                    {marcasConProductos.map((marca) => (
                        <input
                            key={marca}
                            type="button"
                            value={marca}
                            onClick={() => handleClickMarca(marca)}
                            style={{
                                backgroundColor: marcaSeleccionada === marca ? '#000' : '',
                                color: marcaSeleccionada === marca ? '#fff' : '',
                                borderBottom: marcaSeleccionada === marca ? '2px solid #000' : 'none'
                            }}
                        />
                    ))}
                </div>
            )}

            {loading ? (
                <ProductosLoading />
            ) : (
                <div className='Products'>
                    {marcaSeleccionada === 'Todo' && (
                        <>

                            <div className='categoriSection'>
                                <Swiper
                                    effect={'coverflow'}
                                    grabCursor={true}
                                    slidesPerView={'auto'}
                                    id='swiper_container_products'
                                    autoplay={{ delay: 3000 }}
                                >
                                    {productos?.slice(0, 10)?.map(item => (
                                        <SwiperSlide key={item.idProducto} id='SwiperSlide-scroll-products-masvendidos'>
                                            <div className='cardProdcutmasVendido' to={`/moto/${item.idProducto}/${item.titulo.replace(/\s+/g, '-')}`}>
                                                <Anchor to={`/moto/${item.idProducto}/${item.titulo.replace(/\s+/g, '-')}`}>
                                                    <img src={obtenerImagen(item)} alt={item?.titulo} />
                                                </Anchor>

                                                <h6 className='masVendido'>Recomendado</h6>
                                                <div className='cardText'>
                                                    <h5> {moneda} {String(item?.precio)?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                                    <strong>{item.anio} - {item.kilometros} km</strong>
                                                    <h4>{item.titulo}</h4>
                                                </div>
                                                <div className='dataContactProduct'>
                                                    {
                                                        contactos?.filter(filtered => filtered?.idUsuario === item?.idUsuario).length > 0 ? (
                                                            contactos?.filter(filtered => filtered?.idUsuario === item?.idUsuario).map(itemC => (
                                                                <div className='deFlexBtnContact'>

                                                                    <button
                                                                        className='contactBtn'
                                                                        onClick={() => openModal(contactos.find(contacto => contacto?.idUsuario === itemC?.idUsuario))}
                                                                    >
                                                                        Contactar
                                                                    </button>

                                                                    <button className="wpp" onClick={() => handleWhatsappMessage(item)}>
                                                                        <i className="fa fa-whatsapp"></i>
                                                                    </button>



                                                                </div>
                                                            ))
                                                        ) : (

                                                            <div className="deFLexSinDato">
                                                                <img src={logo} alt="Motos de segunda logo" />
                                                                <span>No hay datos del vendedor</span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>


                            {marcasConProductos?.slice(0, 10)?.map((marca) => (
                                <div key={marca} className='categoriSection' ref={ref => marcasRefs.current[marcas.indexOf(marca)] = ref}>
                                    <div className='deFlexTitlesection'>
                                        <h3>{marca}</h3>
                                        <button onClick={() => handleClickMarca(marca)}>
                                            Ver más
                                        </button>
                                    </div>

                                    <Swiper
                                        effect={'coverflow'}
                                        grabCursor={true}
                                        slidesPerView={'auto'}
                                        id='swiper_container_products'
                                    >
                                        {productos?.filter(item => item.marca === marca).map(item => (
                                            <SwiperSlide id='SwiperSlide-scroll-products' key={item.idProducto}>
                                                <div className='cardProdcut' key={item.idProducto} to={`/moto/${item.idProducto}/${item.titulo.replace(/\s+/g, '-')}`}>
                                                    <Anchor to={`/moto/${item.idProducto}/${item.titulo.replace(/\s+/g, '-')}`}>
                                                        <img src={obtenerImagen(item)} alt={item?.titulo} />
                                                    </Anchor>
                                                    <div className='cardTextSelected'>
                                                        <h4>{item.titulo}</h4>
                                                        <strong>{item.anio} - {item.kilometros} km</strong>
                                                        <h5> {moneda} {String(item?.precio)?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                                        <div className='dataContactProduct'>
                                                            {
                                                                contactos?.filter(filtered => filtered?.idUsuario === item?.idUsuario).length > 0 ? (
                                                                    contactos?.filter(filtered => filtered?.idUsuario === item?.idUsuario).map(itemC => (
                                                                        <div className='deFlexBtnContact'>

                                                                            <button
                                                                                className='contactBtn'
                                                                                onClick={() => openModal(contactos.find(contacto => contacto?.idUsuario === itemC?.idUsuario))}
                                                                            >
                                                                                Contactar
                                                                            </button>

                                                                            <button className="wpp" onClick={() => handleWhatsappMessage(item)}>
                                                                                <i className="fa fa-whatsapp"></i>
                                                                            </button>



                                                                        </div>
                                                                    ))
                                                                ) : (

                                                                    <div className="deFLexSinDato">
                                                                        <img src={logo} alt="Motos de segunda logo" />
                                                                        <span>No hay datos del vendedor</span>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        <FontAwesomeIcon icon={faAngleDoubleRight} className='iconCard' />

                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            ))}
                        </>
                    )}

                    <div className='categoriSectionSelected'>
                        {productos
                            ?.filter(item => marcaSeleccionada !== 'Todo' && item.marca === marcaSeleccionada)
                            ?.map(item => (
                                <div className='cardProdcutSelected' key={item.idProducto} to={`/moto/${item.idProducto}/${item.titulo.replace(/\s+/g, '-')}`}>

                                    <Anchor to={`/moto/${item.idProducto}/${item.titulo.replace(/\s+/g, '-')}`}>
                                        <img src={obtenerImagen(item)} alt={item?.titulo} />
                                    </Anchor>
                                    <div className='cardTextSelected'>
                                        <h4>{item.titulo}</h4>
                                        <strong>{item.anio} - {item.kilometros} km</strong>
                                        <h5> {moneda} {String(item?.precio)?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                        <div className='dataContactProduct'>
                                            {
                                                contactos?.filter(filtered => filtered?.idUsuario === item?.idUsuario).length > 0 ? (
                                                    contactos?.filter(filtered => filtered?.idUsuario === item?.idUsuario).map(itemC => (
                                                        <div className='deFlexBtnContact'>

                                                            <button
                                                                className='contactBtn'
                                                                onClick={() => openModal(contactos.find(contacto => contacto?.idUsuario === itemC?.idUsuario))}
                                                            >
                                                                Contactar
                                                            </button>

                                                            <button className="wpp" onClick={() => handleWhatsappMessage(item)}>
                                                                <i className="fa fa-whatsapp"></i>
                                                            </button>



                                                        </div>
                                                    ))
                                                ) : (

                                                    <div className="deFLexSinDato">
                                                        <img src={logo} alt="Motos de segunda logo" />
                                                        <span>No hay datos del vendedor</span>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <FontAwesomeIcon icon={faAngleDoubleRight} className='iconCard' />

                                    </div>

                                </div>
                            ))}
                    </div>
                </div>
            )}

            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="modalData" overlayClassName="overlayModal">
                {contactoSeleccionado && (
                    <div className='DataModal'>
                        <h3>Contacto del Vendedor</h3>
                        <div className="deFLexProfile">
                            <img src={logo} alt="Motos de segunda logo" />
                            <div>
                                <h5>{contactoSeleccionado.tipo}</h5>
                                <h4>{contactoSeleccionado.nombre}</h4>
                            </div>
                        </div>
                        <span>Acompañamiento, confianza y seguridad en la compra de tu vehículo</span>
                        <div className='deFlexBtnsData'>
                            <button onClick={handleWhatsappClick} className='btnWpp'><i className="fa fa-whatsapp"></i>  {contactoSeleccionado?.telefono}</button>
                            <button onClick={handleCallClick} className='btnEmail'><i className="fa fa-phone"></i>  {contactoSeleccionado?.telefono}</button>
                        </div>
                        <button onClick={handleEmailClick} className='btnEmail'><i className="fa fa-envelope"></i>  {contactoSeleccionado?.email}</button>

                    </div>
                )}
            </Modal>


        </div>
    );
}
