import React, { useState, useEffect } from 'react';
import { Link as Anchor } from 'react-router-dom';
import LoginUsuario from '../LoginUsuario/LoginUsuario';
import RegisterUsuario from '../RegisterUsuario/RegisterUsuario';
import './AuthUsuario.css';
import user from '../../images/user.png';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUser } from '@fortawesome/free-solid-svg-icons';
import InfoUserMain from '../Admin/InfoUserMain/InfoUserMain';
import baseURL from '../url';
import Spiner from '../Admin/Spiner/Spiner';
import Logout from '../Admin/Logout/Logout';

export default function AuthUsuario() {
    const [modalContent, setModalContent] = useState('login');
    const [isOpen, setIsOpen] = useState(false);
    const [usuario, setUsuario] = useState({});
    const [loading, setLoading] = useState(true);

    const openModal = () => {
        setIsOpen(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${baseURL}/userLogued.php`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setUsuario(data);
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener datos:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div className='AuthContainerClient'>

            {loading ? (
                <></>
            ) : usuario.idUsuario ? (
                <Anchor to={'/dashboard/productos'} className='FavoriteIcon'>
                    <FontAwesomeIcon icon={faUser} /> <span>Publica tu vehículo</span>
                </Anchor>
            ) : (
                <button onClick={openModal} className='FavoriteIcon'>
                    <FontAwesomeIcon icon={faUser} /> <span>Publica tu vehículo</span>
                </button>
            )}
            <Modal
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                className="modalAuth"
                overlayClassName="overlayAuth"
            >
                <div className="modalAuth-content">
                    <div className='deFlexModal'>
                        <span onClick={() => setIsOpen(false)} className="closeModal">
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </span>
                        <h3>Mi cuenta</h3>
                    </div>
                    {loading ? (
                        <Spiner />
                    ) : usuario.idUsuario ? (
                        <>
                            <InfoUserMain />
                            <Logout />
                        </>
                    ) : (
                        <>
                            <Anchor to={`/`}>
                                <img src={user} alt="imagen" className='logoAtuh' />
                            </Anchor>
                            <div className='authButtons'>
                                <button
                                    onClick={() => setModalContent('login')}
                                    className={`authButtonModal ${modalContent === 'login' ? 'selected' : ''}`}
                                >
                                    Ingresar
                                </button>
                                <button
                                    onClick={() => setModalContent('register')}
                                    className={`authButtonModal ${modalContent === 'register' ? 'selected' : ''}`}
                                >
                                    Registrarse
                                </button>
                            </div>
                            {modalContent === 'login' ? <LoginUsuario /> : <RegisterUsuario />}
                        </>
                    )}
                </div>
            </Modal>
        </div>
    );
}
