import React, { useEffect, useState } from 'react';
import baseURL from '../url';
import './ProductsPage.css';
import '../Products/Products.css';
import 'react-toastify/dist/ReactToastify.css';
import ProductosLoadingPage from '../ProductosLoadingPage/ProductosLoadingPage';
import { Link as Anchor } from "react-router-dom";
import moneda from '../moneda';
import { useMediaQuery } from 'react-responsive';
import logo from '../../images/logo.png';
import Modal from 'react-modal';
export default function ProductsPage() {
    const [productos, setProductos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [ubicacionSeleccionada, setUbicacionSeleccionada] = useState([]);
    const [modeloSeleccionado, setModeloSeleccionado] = useState([]);
    const [cilindradaSeleccionada, setCilindradaSeleccionada] = useState([]);

    const [tipoSeleccionado, setTipoSeleccionado] = useState([]);
    const [anioSeleccionado, setAnioSeleccionado] = useState([]);
    const [condicionSeleccionada, setCondicionSeleccionada] = useState([]);
    const [kilometrosSeleccionado, setKilometrosSeleccionado] = useState([]);
    const [marcaSeleccionada, setMarcaSeleccionadao] = useState([]);
    const [combustibleSeleccionado, setCombustibleSeleccionado] = useState([]);
    const [precioMinimo, setPrecioMinimo] = useState(0);
    const [precioMaximo, setPrecioMaximo] = useState(0);
    const [precioActual, setPrecioActual] = useState(0);
    const [mkMinimo, setKmMinimo] = useState(0);
    const [mkMaximo, setKmMaximo] = useState(0);
    const [mkActual, setKmActual] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [ordenActivo, setOrdenActivo] = useState('');
    const [productosVisible, setProductosVisible] = useState(15);
    const [mostrarMasUbicacion, setMostrarMasUbicacion] = useState(false);
    const [mostrarMasModelo, setMostrarMasModelo] = useState(false);
    const [mostrarMasMarca, setMostrarMasMarca] = useState(false);
    const [mostrarMasCilindrada, setMostrarMasCilindrada] = useState(false);
    const [mostrarMasTipo, setMostrarMasTipo] = useState(false);
    const [mostrarMasAnio, setMostrarMasAnio] = useState(false);
    const [mostrarMasCondicion, setMostrarMasCondicion] = useState(false);
    const [mostrarMasCombustible, setMostrarMasCombustible] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [contactos, setContactos] = useState([]);
    const [contactoSeleccionado, setContactoSeleccionado] = useState(null);

    const openModal = (contacto) => {
        setContactoSeleccionado(contacto);
        setModalIsOpen(true);
    };


    const closeModal = () => {
        setModalIsOpen(false);
    };
    const isMediaQuery = useMediaQuery({ query: '(max-width: 900px)' });

    useEffect(() => {
        // Cerrar el modal si la pantalla es pequeña al iniciar
        if (isMediaQuery) {
            setIsModalOpen(false);
        }
        cargarProductos();
        cargarContacto();
    }, [isMediaQuery]);
    useEffect(() => {
        if (!loading && productos.length > 0) {
            const maxKilometros = Math.max(...productos.map(producto => producto.kilometros));
            setKmMaximo(maxKilometros);
            setKmActual(maxKilometros);
        }
    }, [productos, loading]);

    const cargarContacto = () => {
        fetch(`${baseURL}/contactoGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setContactos(data.contacto || []);
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };
    const mezclarArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const cargarProductos = () => {
        fetch(`${baseURL}/productosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                console.log('Productos cargados:', data.productos); // Debug
                const productosMezclados = mezclarArray(data.productos);
                setProductos(productosMezclados);
                const maxPrecio = calcularPrecioMaximo(productosMezclados);
                setPrecioMaximo(maxPrecio);
                setPrecioActual(maxPrecio);
                setLoading(false);
            })
            .catch(error => console.error('Error al cargar productos:', error));
    };

    const calcularPrecioMaximo = (productos) => {
        return Math.max(...productos.map(producto => producto.precio));
    };

    const obtenerImagen = (item) => {
        if (item.imagen1) {
            return item.imagen1;
        } else if (item.imagen2) {
            return item.imagen2;
        } else if (item.imagen3) {
            return item.imagen3;
        } else if (item.imagen4) {
            return item.imagen4;
        }
        return null;
    };

    const handlePrecioChange = (event) => {
        const precio = parseFloat(event.target.value);
        setPrecioActual(precio);
    };
    const handlekmChange = (event) => {
        const km = parseFloat(event.target.value);
        setKmActual(km);
    };

    const handleCheckboxChange = (event, setter) => {
        const { value, checked } = event.target;
        setter(prev => checked ? [...prev, value] : prev.filter(item => item !== value));
    };

    const filterProductos = (producto) => {
        console.log('Filtrando producto:', producto); // Debug
        const ubicacionMatch = ubicacionSeleccionada.length === 0 || ubicacionSeleccionada.includes(producto.ubicacion);
        const modeloMatch = modeloSeleccionado.length === 0 || modeloSeleccionado.includes(producto.modelo);
        const marcaoMatch = marcaSeleccionada.length === 0 || marcaSeleccionada.includes(producto.marca);
        const cilindradaMatch =
            cilindradaSeleccionada.length === 0 ||
            cilindradaSeleccionada.some(cilindrada => {
                const [min, max] = cilindrada.split('-').map(Number);
                return producto.cilindrada >= min && producto.cilindrada < (max || Infinity);
            });
        const tipoMatch = tipoSeleccionado.length === 0 || tipoSeleccionado.includes(producto.tipo);
        const anioMatch = anioSeleccionado.length === 0 || anioSeleccionado.includes(producto.anio.toString());
        const condicionMatch = condicionSeleccionada.length === 0 || condicionSeleccionada.includes(producto.condicion);
        const combustibleMatch = combustibleSeleccionado.length === 0 || combustibleSeleccionado.includes(producto.combustible);
        const precioMatch = producto.precio >= precioMinimo && producto.precio <= precioActual;
        const kilometrosMatch = producto.kilometros >= mkMinimo && producto.kilometros <= mkActual;
        return ubicacionMatch && modeloMatch && cilindradaMatch && tipoMatch && anioMatch && condicionMatch && combustibleMatch && precioMatch && kilometrosMatch && marcaoMatch;
    };


    const productosFiltrados = productos.filter(filterProductos);

    const resetFiltros = () => {
        setUbicacionSeleccionada([]);
        setModeloSeleccionado([]);
        setCilindradaSeleccionada([]);
        setTipoSeleccionado([]);
        setAnioSeleccionado([]);
        setCondicionSeleccionada([]);
        setCombustibleSeleccionado([]);
        setMarcaSeleccionadao([]);
        setPrecioActual(precioMaximo);
        setKmActual(mkMaximo);
        setIsModalOpen(false);
        setOrdenActivo('');
        setProductos(mezclarArray(productos));
    };


    const ordenarPorPrecioAscendente = () => {
        if (ordenActivo === 'ascendente') {
            setProductos(mezclarArray(productos));  // Restaurar el orden original o mezclar
            setOrdenActivo('');  // Desactivar la selección
        } else {
            const productosOrdenados = [...productos].sort((a, b) => a.precio - b.precio);
            setProductos(productosOrdenados);
            setOrdenActivo('ascendente');
        }
    };

    const ordenarPorPrecioDescendente = () => {
        if (ordenActivo === 'descendente') {
            setProductos(mezclarArray(productos));  // Restaurar el orden original o mezclar
            setOrdenActivo('');  // Desactivar la selección
        } else {
            const productosOrdenados = [...productos].sort((a, b) => b.precio - a.precio);
            setProductos(productosOrdenados);
            setOrdenActivo('descendente');
        }
    };

    const mostrarMasProductos = () => {
        setProductosVisible(prev => prev + 10);
    };




    const handleWhatsappMessage = (producto) => {
        console.log('Producto:', producto);

        const contacto = contactos.find(c => c.idUsuario === producto.idUsuario);

        if (!contacto) {
            console.error('No se encontró el contacto para el usuario');
            return;
        }

        const phoneNumber = contacto.telefono;
        const name = contacto.nombre;
        const title = encodeURIComponent(producto?.titulo?.replace(/\s+/g, '-'));
        const formattedPrice = Number(producto?.precio).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        const message = `Hola ${name}🌟, quisiera más información sobre\n\n✅ *${title}*\n      ${moneda} ${formattedPrice}`;

        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

        window.open(whatsappUrl, '_blank');
    };


    // Función para manejar el clic en WhatsApp
    const handleWhatsappClick = () => {
        const phoneNumber = contactoSeleccionado.telefono;
        const message = `Hola ${contactoSeleccionado.nombre}, me gustaría obtener más información.`;
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, '_blank');
    };

    // Función para manejar el clic en Teléfono (realizar una llamada)
    const handleCallClick = () => {
        const phoneNumber = contactoSeleccionado.telefono;
        window.location.href = `tel:${phoneNumber}`;
    };

    // Función para manejar el clic en Email (abrir el cliente de correo)
    const handleEmailClick = () => {
        const email = contactoSeleccionado.email;
        const subject = encodeURIComponent("Solicitud de información");
        const body = encodeURIComponent(`Hola ${contactoSeleccionado.nombre},\n\nMe gustaría obtener más información sobre el producto.`);
        window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
    };
    const [conteoCilindrada, setConteoCilindrada] = useState({
        "0-150": 0,
        "150-300": 0,
        "300-": 0
    });

    useEffect(() => {
        const contarProductos = () => {
            const conteo = {
                "0-150": 0,
                "150-300": 0,
                "300-": 0
            };

            productos.forEach(producto => {
                if (producto.cilindrada < 150) {
                    conteo["0-150"]++;
                } else if (producto.cilindrada < 300) {
                    conteo["150-300"]++;
                } else {
                    conteo["300-"]++;
                }
            });

            setConteoCilindrada(conteo);
        };

        contarProductos();
    }, [productos]);


    return (
        <div className='ProductsContainPage'>


            {productos?.length > 0 && (

                <div className='containFiltros'>

                    {isMediaQuery && !isModalOpen && (
                        <button onClick={() => setIsModalOpen(true)} className='filterButton'>
                            Filtrar
                        </button>
                    )}
                    {(!isMediaQuery || isModalOpen) && (
                        <div className="modalFilter">
                            <div className="modal-content-filter">
                                {isMediaQuery && (
                                    <div className='btnsFilter'>
                                        <button onClick={resetFiltros} className="closeModalButton">
                                            Borrar Filtros
                                        </button>
                                        <button onClick={() => setIsModalOpen(false)} className='closeModalButton'>Aplicar filtros</button>
                                    </div>
                                )}
                                <div className='filtrosPage'>
                                    <div className='inputsGrap'>
                                        {/* Filtros para Ubicación */}
                                        <div className='filterInp'>
                                            <legend>Ubicación</legend>
                                            {Array.from(new Set(productos.map(p => p.ubicacion))).length > 4 ? (
                                                <div>
                                                    {Array.from(new Set(productos.map(p => p.ubicacion)))
                                                        .slice(0, mostrarMasUbicacion ? undefined : 3)
                                                        .map(ubicacion => (
                                                            <div key={ubicacion}>
                                                                <input
                                                                    type="checkbox"
                                                                    id={`ubicacion-${ubicacion}`}
                                                                    value={ubicacion}
                                                                    checked={ubicacionSeleccionada.includes(ubicacion)}
                                                                    onChange={(e) => handleCheckboxChange(e, setUbicacionSeleccionada)}
                                                                    className={ubicacionSeleccionada.includes(ubicacion) ? 'activeCheck' : ''}
                                                                />
                                                                <label htmlFor={`ubicacion-${ubicacion}`}>
                                                                    {ubicacion} <span>({productos.filter(p => p.ubicacion === ubicacion).length})</span>
                                                                </label>
                                                            </div>
                                                        ))}
                                                    <button onClick={() => setMostrarMasUbicacion(!mostrarMasUbicacion)}>
                                                        {mostrarMasUbicacion ? 'Mostrar menos' : 'Mostrar más'}
                                                    </button>
                                                </div>
                                            ) : (
                                                Array.from(new Set(productos.map(p => p.ubicacion)))
                                                    .map(ubicacion => (
                                                        <div key={ubicacion}>
                                                            <input
                                                                type="checkbox"
                                                                id={`ubicacion-${ubicacion}`}
                                                                value={ubicacion}
                                                                checked={ubicacionSeleccionada.includes(ubicacion)}
                                                                onChange={(e) => handleCheckboxChange(e, setUbicacionSeleccionada)}
                                                                className={ubicacionSeleccionada.includes(ubicacion) ? 'activeCheck' : ''}
                                                            />
                                                            <label htmlFor={`ubicacion-${ubicacion}`}>
                                                                {ubicacion} <span>({productos.filter(p => p.ubicacion === ubicacion).length})</span>
                                                            </label>
                                                        </div>
                                                    ))
                                            )}
                                        </div>

                                        <div className='filterInp'>
                                            <legend>Marca</legend>
                                            {Array.from(new Set(productos.map(p => p.marca))).length > 4 ? (
                                                <div>
                                                    {Array.from(new Set(productos.map(p => p.marca)))
                                                        .slice(0, mostrarMasMarca ? undefined : 3)
                                                        .map(marca => (
                                                            <div key={marca}>
                                                                <input
                                                                    type="checkbox"
                                                                    id={`marca-${marca}`}
                                                                    value={marca}
                                                                    checked={marcaSeleccionada.includes(marca)}
                                                                    onChange={(e) => handleCheckboxChange(e, setMarcaSeleccionadao)}
                                                                    className={marcaSeleccionada.includes(marca) ? 'activeCheck' : ''}
                                                                />
                                                                <label htmlFor={`marca-${marca}`}>
                                                                    {marca} <span>({productos.filter(p => p.marca === marca).length})</span>
                                                                </label>
                                                            </div>
                                                        ))}
                                                    <button onClick={() => setMostrarMasMarca(!mostrarMasMarca)}>
                                                        {mostrarMasMarca ? 'Mostrar menos' : 'Mostrar más'}
                                                    </button>
                                                </div>
                                            ) : (
                                                Array.from(new Set(productos.map(p => p.marca)))
                                                    .map(marca => (
                                                        <div key={marca}>
                                                            <input
                                                                type="checkbox"
                                                                id={`marca-${marca}`}
                                                                value={marca}
                                                                checked={marcaSeleccionada.includes(marca)}
                                                                onChange={(e) => handleCheckboxChange(e, setMarcaSeleccionadao)}
                                                                className={marcaSeleccionada.includes(marca) ? 'activeCheck' : ''}
                                                            />
                                                            <label htmlFor={`marca-${marca}`}>
                                                                {marca} <span>({productos.filter(p => p.marca === marca).length})</span>
                                                            </label>
                                                        </div>
                                                    ))
                                            )}
                                        </div>


                                        {/* Filtros para Modelo */}
                                        <div className='filterInp'>
                                            <legend>Modelo</legend>
                                            {Array.from(new Set(productos.map(p => p.modelo))).length > 4 ? (
                                                <div>
                                                    {Array.from(new Set(productos.map(p => p.modelo)))
                                                        .slice(0, mostrarMasModelo ? undefined : 3)
                                                        .map(modelo => (
                                                            <div key={modelo}>
                                                                <input
                                                                    type="checkbox"
                                                                    id={`modelo-${modelo}`}
                                                                    value={modelo}
                                                                    checked={modeloSeleccionado.includes(modelo)}
                                                                    onChange={(e) => handleCheckboxChange(e, setModeloSeleccionado)}
                                                                    className={modeloSeleccionado.includes(modelo) ? 'activeCheck' : ''}
                                                                />
                                                                <label htmlFor={`modelo-${modelo}`}>
                                                                    {modelo} <span>({productos.filter(p => p.modelo === modelo).length})</span>
                                                                </label>
                                                            </div>
                                                        ))}
                                                    <button onClick={() => setMostrarMasModelo(!mostrarMasModelo)}>
                                                        {mostrarMasModelo ? 'Mostrar menos' : 'Mostrar más'}
                                                    </button>
                                                </div>
                                            ) : (
                                                Array.from(new Set(productos.map(p => p.modelo)))
                                                    .map(modelo => (
                                                        <div key={modelo}>
                                                            <input
                                                                type="checkbox"
                                                                id={`modelo-${modelo}`}
                                                                value={modelo}
                                                                checked={modeloSeleccionado.includes(modelo)}
                                                                onChange={(e) => handleCheckboxChange(e, setModeloSeleccionado)}
                                                                className={modeloSeleccionado.includes(modelo) ? 'activeCheck' : ''}
                                                            />
                                                            <label htmlFor={`modelo-${modelo}`}>
                                                                {modelo} <span>({productos.filter(p => p.modelo === modelo).length})</span>
                                                            </label>
                                                        </div>
                                                    ))
                                            )}
                                        </div>



                                        {/* Filtros para Cilindrada */}
                                        <div className='filterInp'>
                                            <legend>Cilindrada</legend>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    id="cilindrada-0-150"
                                                    value="0-150"
                                                    checked={cilindradaSeleccionada.includes("0-150")}
                                                    onChange={(e) => handleCheckboxChange(e, setCilindradaSeleccionada)}
                                                />
                                                <label htmlFor="cilindrada-0-150"><>0 - 150 cc</> <span>({conteoCilindrada["0-150"]})</span></label>
                                            </div>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    id="cilindrada-150-300"
                                                    value="150-300"
                                                    checked={cilindradaSeleccionada.includes("150-300")}
                                                    onChange={(e) => handleCheckboxChange(e, setCilindradaSeleccionada)}
                                                />
                                                <label htmlFor="cilindrada-150-300"><>150 - 300 cc</> <span>({conteoCilindrada["150-300"]})</span></label>
                                            </div>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    id="cilindrada-300"
                                                    value="300-"
                                                    checked={cilindradaSeleccionada.includes("300-")}
                                                    onChange={(e) => handleCheckboxChange(e, setCilindradaSeleccionada)}
                                                />
                                                <label htmlFor="cilindrada-300"><>300 cc en adelante</> <span>({conteoCilindrada["300-"]})</span></label>
                                            </div>
                                        </div>





                                        {/* Filtros para Tipo */}
                                        <div className='filterInp'>
                                            <legend>Tipo</legend>
                                            {Array.from(new Set(productos.map(p => p.tipo))).length > 4 ? (
                                                <div>
                                                    {Array.from(new Set(productos.map(p => p.tipo)))
                                                        .slice(0, mostrarMasTipo ? undefined : 3)
                                                        .map(tipo => (
                                                            <div key={tipo}>
                                                                <input
                                                                    type="checkbox"
                                                                    id={`tipo-${tipo}`}
                                                                    value={tipo}
                                                                    checked={tipoSeleccionado.includes(tipo)}
                                                                    onChange={(e) => handleCheckboxChange(e, setTipoSeleccionado)}
                                                                    className={tipoSeleccionado.includes(tipo) ? 'activeCheck' : ''}
                                                                />
                                                                <label htmlFor={`tipo-${tipo}`}>
                                                                    {tipo} <span>({productos.filter(p => p.tipo === tipo).length})</span>
                                                                </label>
                                                            </div>
                                                        ))}
                                                    <button onClick={() => setMostrarMasTipo(!mostrarMasTipo)}>
                                                        {mostrarMasTipo ? 'Mostrar menos' : 'Mostrar más'}
                                                    </button>
                                                </div>
                                            ) : (
                                                Array.from(new Set(productos.map(p => p.tipo)))
                                                    .map(tipo => (
                                                        <div key={tipo}>
                                                            <input
                                                                type="checkbox"
                                                                id={`tipo-${tipo}`}
                                                                value={tipo}
                                                                checked={tipoSeleccionado.includes(tipo)}
                                                                onChange={(e) => handleCheckboxChange(e, setTipoSeleccionado)}
                                                                className={tipoSeleccionado.includes(tipo) ? 'activeCheck' : ''}
                                                            />
                                                            <label htmlFor={`tipo-${tipo}`}>
                                                                {tipo} <span>({productos.filter(p => p.tipo === tipo).length})</span>
                                                            </label>
                                                        </div>
                                                    ))
                                            )}
                                        </div>



                                        {/* Filtros para Año */}
                                        <div className='filterInp'>
                                            <legend>Año</legend>
                                            {Array.from(new Set(productos.map(p => p.anio))).length > 4 ? (
                                                <div>
                                                    {Array.from(new Set(productos.map(p => p.anio)))
                                                        .slice(0, mostrarMasAnio ? undefined : 3)
                                                        .map(anio => (
                                                            <div key={anio}>
                                                                <input
                                                                    type="checkbox"
                                                                    id={`anio-${anio}`}
                                                                    value={anio}
                                                                    checked={anioSeleccionado.includes(anio.toString())}
                                                                    onChange={(e) => handleCheckboxChange(e, setAnioSeleccionado)}
                                                                    className={anioSeleccionado.includes(anio.toString()) ? 'activeCheck' : ''}
                                                                />
                                                                <label htmlFor={`anio-${anio}`}>
                                                                    {anio} <span> ({productos.filter(p => p.anio === anio).length})</span>
                                                                </label>
                                                            </div>
                                                        ))}
                                                    <button onClick={() => setMostrarMasAnio(!mostrarMasAnio)}>
                                                        {mostrarMasAnio ? 'Mostrar menos' : 'Mostrar más'}
                                                    </button>
                                                </div>
                                            ) : (
                                                Array.from(new Set(productos.map(p => p.anio)))
                                                    .map(anio => (
                                                        <div key={anio}>
                                                            <input
                                                                type="checkbox"
                                                                id={`anio-${anio}`}
                                                                value={anio}
                                                                checked={anioSeleccionado.includes(anio.toString())}
                                                                onChange={(e) => handleCheckboxChange(e, setAnioSeleccionado)}
                                                                className={anioSeleccionado.includes(anio.toString()) ? 'activeCheck' : ''}
                                                            />
                                                            <label htmlFor={`anio-${anio}`}>
                                                                {anio} <span> ({productos.filter(p => p.anio === anio).length})</span>
                                                            </label>
                                                        </div>
                                                    ))
                                            )}
                                        </div>



                                        {/* Filtros para Condición */}
                                        <div className='filterInp'>
                                            <legend>Condición</legend>
                                            {Array.from(new Set(productos.map(p => p.condicion))).length > 4 ? (
                                                <div>
                                                    {Array.from(new Set(productos.map(p => p.condicion)))
                                                        .slice(0, mostrarMasCondicion ? undefined : 3)
                                                        .map(condicion => (
                                                            <div key={condicion}>
                                                                <input
                                                                    type="checkbox"
                                                                    id={`condicion-${condicion}`}
                                                                    value={condicion}
                                                                    checked={condicionSeleccionada.includes(condicion)}
                                                                    onChange={(e) => handleCheckboxChange(e, setCondicionSeleccionada)}
                                                                    className={condicionSeleccionada.includes(condicion) ? 'activeCheck' : ''}
                                                                />
                                                                <label htmlFor={`condicion-${condicion}`}>
                                                                    {condicion} <span> ({productos.filter(p => p.condicion === condicion).length})</span>
                                                                </label>
                                                            </div>
                                                        ))}
                                                    <button onClick={() => setMostrarMasCondicion(!mostrarMasCondicion)}>
                                                        {mostrarMasCondicion ? 'Mostrar menos' : 'Mostrar más'}
                                                    </button>
                                                </div>
                                            ) : (
                                                Array.from(new Set(productos.map(p => p.condicion)))
                                                    .map(condicion => (
                                                        <div key={condicion}>
                                                            <input
                                                                type="checkbox"
                                                                id={`condicion-${condicion}`}
                                                                value={condicion}
                                                                checked={condicionSeleccionada.includes(condicion)}
                                                                onChange={(e) => handleCheckboxChange(e, setCondicionSeleccionada)}
                                                                className={condicionSeleccionada.includes(condicion) ? 'activeCheck' : ''}
                                                            />
                                                            <label htmlFor={`condicion-${condicion}`}>
                                                                {condicion} <span> ({productos.filter(p => p.condicion === condicion).length})</span>
                                                            </label>
                                                        </div>
                                                    ))
                                            )}
                                        </div>






                                        {/* Filtros para Combustible */}
                                        <div className='filterInp'>
                                            <legend>Combustible</legend>
                                            {Array.from(new Set(productos.map(p => p.combustible))).length > 4 ? (
                                                <div>
                                                    {Array.from(new Set(productos.map(p => p.combustible)))
                                                        .slice(0, mostrarMasCombustible ? undefined : 3)
                                                        .map(combustible => (
                                                            <div key={combustible}>
                                                                <input
                                                                    type="checkbox"
                                                                    id={`combustible-${combustible}`}
                                                                    value={combustible}
                                                                    checked={combustibleSeleccionado.includes(combustible)}
                                                                    onChange={(e) => handleCheckboxChange(e, setCombustibleSeleccionado)}
                                                                    className={combustibleSeleccionado.includes(combustible) ? 'activeCheck' : ''}
                                                                />
                                                                <label htmlFor={`combustible-${combustible}`}>
                                                                    {combustible} <span>({productos.filter(p => p.combustible === combustible).length})</span>
                                                                </label>
                                                            </div>
                                                        ))}
                                                    <button onClick={() => setMostrarMasCombustible(!mostrarMasCombustible)}>
                                                        {mostrarMasCombustible ? 'Mostrar menos' : 'Mostrar más'}
                                                    </button>
                                                </div>
                                            ) : (
                                                Array.from(new Set(productos.map(p => p.combustible)))
                                                    .map(combustible => (
                                                        <div key={combustible}>
                                                            <input
                                                                type="checkbox"
                                                                id={`combustible-${combustible}`}
                                                                value={combustible}
                                                                checked={combustibleSeleccionado.includes(combustible)}
                                                                onChange={(e) => handleCheckboxChange(e, setCombustibleSeleccionado)}
                                                                className={combustibleSeleccionado.includes(combustible) ? 'activeCheck' : ''}
                                                            />
                                                            <label htmlFor={`combustible-${combustible}`}>
                                                                {combustible} <span>({productos.filter(p => p.combustible === combustible).length})</span>
                                                            </label>
                                                        </div>
                                                    ))
                                            )}
                                        </div>


                                        {/* Filtros para Kilómetros */}
                                        <div className='filterInp'>
                                            <legend>Kilómetros</legend>

                                            <input
                                                type="range"
                                                id="mkActual"
                                                min={0}
                                                max={mkMaximo}
                                                value={mkActual}
                                                onChange={handlekmChange}
                                            />
                                            <span>{mkActual?.toLocaleString('es-AR')} km</span>

                                        </div>




                                    </div>

                                    {/* Rango de precio */}
                                    <div className="ordenarBotones">
                                        <button
                                            onClick={ordenarPorPrecioAscendente}
                                            className={ordenActivo === 'ascendente' ? 'activeCheck' : ''}
                                        >
                                            Menor precio
                                        </button>
                                        <button
                                            onClick={ordenarPorPrecioDescendente}
                                            className={ordenActivo === 'descendente' ? 'activeCheck' : ''}
                                        >
                                            Mayor precio
                                        </button>
                                    </div>


                                    <div className='filterInp'>

                                        <label htmlFor="precioActual">Precio Actual:</label>
                                        <input
                                            type="range"
                                            id="precioActual"
                                            min={0}
                                            max={precioMaximo}
                                            value={precioActual}
                                            onChange={handlePrecioChange}
                                        />
                                        <span>{moneda}{precioActual?.toLocaleString('es-AR')}</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )
            }
            {/* Productos filtrados */}
            <div className='ProductsGsrap'>
                {loading ? (
                    <ProductosLoadingPage />
                ) : productosFiltrados.length === 0 ? (
                    <p className='no-products-message'>No hay resultados para mostrar.</p>
                ) : (
                    <>
                        <div className='ProductsGrap'>
                            {productosFiltrados.slice(0, productosVisible).map(producto => (
                                <div key={producto.idProducto} className='cardProdcutmasVendido '>

                                    <Anchor to={`/moto/${producto?.idProducto}/${producto?.titulo.replace(/\s+/g, '-')}`}>
                                        <img src={obtenerImagen(producto)} alt={producto?.titulo} />
                                    </Anchor>
                                    <div className='cardText'>
                                        <h5> {moneda} {String(producto?.precio)?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                        <strong>{producto.anio} - {producto.kilometros} km</strong>
                                        <h4>{producto.titulo}</h4>
                                    </div>
                                    {
                                        contactos?.filter(filtered => filtered?.idUsuario === producto?.idUsuario).length > 0 ? (
                                            contactos?.filter(filtered => filtered?.idUsuario === producto?.idUsuario).map(itemC => (
                                                <div className='deFlexBtnContact'>

                                                    <button
                                                        className='contactBtn'
                                                        onClick={() => openModal(contactos.find(contacto => contacto?.idUsuario === itemC?.idUsuario))}
                                                    >
                                                        Contactar
                                                    </button>

                                                    <button className="wpp" onClick={() => handleWhatsappMessage(producto)}>
                                                        <i className="fa fa-whatsapp"></i>
                                                    </button>



                                                </div>
                                            ))
                                        ) : (

                                            <div className="deFLexSinDato">
                                                <img src={logo} alt="Motos de segunda logo" />
                                                <span>No hay datos del vendedor</span>
                                            </div>
                                        )
                                    }
                                </div>
                            ))}

                        </div> {productosFiltrados.length > productosVisible && (
                            <button onClick={mostrarMasProductos} className='showMoreButton'>
                                Mostrar más
                            </button>
                        )}

                    </>
                )}
            </div>
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="modalData" overlayClassName="overlayModal">
                {contactoSeleccionado && (
                    <div className='DataModal'>
                        <h3>Contacto del Vendedor</h3>
                        <div className="deFLexProfile">
                            <img src={logo} alt="Motos de segunda logo" />
                            <div>
                                <h5>{contactoSeleccionado.tipo}</h5>
                                <h4>{contactoSeleccionado.nombre}</h4>
                            </div>
                        </div>
                        <span>Acompañamiento, confianza y seguridad en la compra de tu vehículo</span>
                        <div className='deFlexBtnsData'>
                            <button onClick={handleWhatsappClick} className='btnWpp'><i className="fa fa-whatsapp"></i>  {contactoSeleccionado?.telefono}</button>
                            <button onClick={handleCallClick} className='btnEmail'><i className="fa fa-phone"></i>  {contactoSeleccionado?.telefono}</button>
                        </div>
                        <button onClick={handleEmailClick} className='btnEmail'><i className="fa fa-envelope"></i>  {contactoSeleccionado?.email}</button>

                    </div>
                )}
            </Modal>


        </div >
    );
}
